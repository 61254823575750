<template>
  <v-menu
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-content-click="false"
    :close-on-click="false"
    :nudge-width="600"
    top
    offset-y
  >
    <template #activator="{ on: onMenu, attrs: attrsMenu }">
      <v-tooltip bottom>
        <template #activator="{on: onTooltip, attrs: attrsTooltip}">
          <v-badge
            overlap
            bordered
            :content="filterBadgeMessage"
            :value="visibleBadge"
            :color="computedFilterBadgeColor"
          >
            <!-- activator command button -->
            <v-btn
              v-on="{ ...onMenu, ...onTooltip }"
              v-bind="{ ...attrsMenu, ...attrsTooltip }"
              fab
              :outlined="buttonRecommendedOutlined(visibleBadge)"
              :text="buttonRecommendedTextStyle"
              :color="buttonColorClass"
              :class="buttonRecommendedClass()"
            >
              <v-icon>
                <slot name="iconName">
                  {{ iconFilterMenu }}
                </slot>
              </v-icon>
            </v-btn>
          </v-badge>
        </template>

        <!-- Tooltip -->
        <template #default>
          <slot name="toolTip">
            <span>Search Filter</span>
          </slot>
        </template>
      </v-tooltip>
    </template>

    <slot name="default"></slot>
  </v-menu>
</template>

<script>
// design
import { iconFilterMenu } from "@/design/icon/iconConst";
import { getSuccessColorClass } from "@/design/colors/Color";

// mixins
import { buttonSettingsMixin } from "@/mixins/shared/base/settings/buttonSettingsMixin";
import { slottableMixin } from "@/mixins/shared/slottable/slottableMixin";

export default {
  name: "SearchFilterPopoverMenu",
  inheritAttrs: false,
  mixins: [buttonSettingsMixin, slottableMixin],
  data() {
    return {
      iconFilterMenu: iconFilterMenu
    };
  },
  props: {
    visibleBadge: {
      type: Boolean,
      default: false
    },
    filterBadgeMessage: {
      type: String,
      default: ""
    },
    filterBadgeColorClass: {
      type: String,
      default: undefined
    }
  },
  computed: {
    computedFilterBadgeColor() {
      return this.filterBadgeColorClass
        ? this.filterBadgeColorClass
        : getSuccessColorClass();
    }
  }
};
</script>
